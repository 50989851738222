import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FAQ from "../components/faq/"

export default () => {
  const data = useStaticQuery(graphql`
    query FaqQuery {
      allContentfulQuestions {
        edges {
          node {
            question
            answer {
              answer
            }
          }
        }
      }
    }
  `)

  const { edges: questions } = data.allContentfulQuestions

  return <FAQ questions={questions} />
}
