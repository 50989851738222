import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Hr from "../components/hr"
import { Block } from "../components/Block"
import { Text } from "../components/Text"
import FAQ from "../lib/useFaq"

const Title = styled.h2`
  padding: 0;
  margin-bottom: 1.8rem;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
`

const Description = styled(Text)`
  line-height: 2.1rem;
  text-align: center;
`

const Faq = () => {
  const data = useStaticQuery(graphql`
    query FaqTitleQuery {
      contentfulFaqPage {
        title
        description {
          description
        }
      }
    }
  `)

  const {
    title,
    description: { description },
  } = data.contentfulFaqPage

  return (
    <>
      <SEO title="Frågor & svar" />
      <Block>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Hr />
        <FAQ />
      </Block>
    </>
  )
}

export default Faq
