import styled from "styled-components"
import { colors } from "../Style"

export const Question = styled.dt`
  margin-bottom: 1rem;
  color: ${colors.white};
  font-size: 1.8rem;
  font-weight: 700;
`

export const Answer = styled.dd`
  font-size: 1.6rem;
  line-height: 1.2;
  color: ${colors.gray["100"]};
`

export const Row = styled.div`
  margin-bottom: 3.4rem;

  &:last-child {
    margin-bottom: 0;
  }
`
