import React from "react"
import { Question, Answer, Row } from "./Style"

export default function FAQ({ questions }) {
  return (
    <dl>
      {questions.map((question, i) => (
        <Row key={"faq-" + i}>
          <Question>{question.node.question}</Question>
          <Answer>{question.node.answer.answer}</Answer>
        </Row>
      ))}
    </dl>
  )
}
